<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 py-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-xl-12 col-md-5 col-lg-6">
        <!-- Image -->
        <img src="./../../assets/svg/img 8.svg" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..." data-aos="fade-up" data-aos-delay="100" width="50%" height="100%" />
      </div>
      <div class="col-xl-12 col-md-7 col-lg-6 aos-init aos-animate" data-aos="fade-up">
        <h3 class="py-4">Privacy and Policy</h3>
        <h5>LAST UPDATE: May, 2022</h5>

        <h5>PRIVACY POLICY</h5>

        <p>Wize.Health Inc. (“Wize.Health”) is committed to protecting the privacy rights of all persons with whom it deals.
          We will take every step to maintain the confidentiality of your personal information. This Privacy Policy
          applies to your use of our website and of our services. This Privacy Policy governs Wize.Health’s collection,
          use and/or disclosure of an individual's personal information. </p>

        <h5>Accountability</h5>

        <p>Wize.Health is accountable for all personal information under its care and control. Wize.Health’s Privacy Officer
          is designated as the person responsible for the Privacy Policy and for the personal information received by
          Wize.Health. The Privacy Officer can be reached as follows: by email at [insert email address] or by telephone
          at [insert phone number]. </p>

        <p>Any questions relating to Wize.Health’s handling of personal information should be directed to our Privacy
          Officer. </p>

        <h5>Purpose</h5>

        <p>In order to facilitate the operation of our website and to provide our services, we may collect personal
          information from you. This information may include but is not limited to, your name, email address, postal
          code, date of birth, medical conditions, and medication histories, address, phone number, credit card number
          and bank account information. </p>

        <p>Wize.Health is required to collect and use personal information in order to provide its services, to open,
          maintain and process payment transactions, to administer and process customer surveys, special promotions and
          contests, for other marketing activities, to maintain accounts, to process and deliver invoices and
          statements, and other internal administrative functions such as internal Usage Data analysis and identifying
          usage trends. As a result, personal information can be collected when you use our website. </p>

        <p>Your IP address, your computer's operating system, the browser type and language are automatically collected
          each time you use the website. Moreover, the identity of the website you came from will also be collected, as
          will your web request and the date and time of your visit and the site pages visited. Other information such
          as your name, address, and email address is collected in order to allow us to administer the website, to
          identify and contact users and in order to conduct website maintenance. For audit and other internal and
          administrative purposes Wize.Health may also keep a record of all information you contribute to the website.
        </p>


        <p>The website will also use cookies to keep track of your activities and behaviour while on the website. When
          you visit the website, we send one or more cookies – a small file containing a string of characters – to your
          computer that uniquely identifies your browser. Most browsers are initially set up to accept cookies, but you
          can reset your browser to refuse all cookies or to indicate when a cookie is being sent.
        </p>
        <h5>How We Use Your Personal Information</h5>

        <p>We will share your personal information with third parties only in the ways that are described in this
          Privacy Policy. We do not sell your personal information to third parties. </p>

        <p>We may provide your personal information to our affiliates as well as to companies that provide services to
          help us with our business activities and to monitor and analyze the use of our services. These companies are
          authorized to use your personal information only as necessary to provide these services to us. </p>
        <p>From time to time, Wize.Health may provide personal information to third parties who administer our products
          and services, to customer statement producers and to mailing services that process and mail statements. </p>
        <p>In all cases, Wize.Health limits the amount and type of information collected to that which is necessary to
          meet the purposes described above. Wize.Health undertakes to keep all personal information it collects strictly
          confidential. All information that does not have a specific purpose or that no longer fulfills its intended
          purpose will be destroyed in a secure fashion. Wize.Health will only retain personal information for the
          duration of its intended use or as otherwise required by law. </p>
        <p>Wize.Health does not sell, rent, loan, trade, or lease any personal information collected, as well as any
          information that personally identifies you or allows us to connect you to any third parties who are not
          involved in our relationship with you. </p>
        <p>In the event of a transaction including the sale of same or all of Wize.Health, personal information will
          generally be one of the transferred business assets and, this, will become subject to a different privacy
          policy. </p>

        <p>We may also disclose your personal information as required by law to comply with a subpoena or similar legal
          process when we believe in good faith that disclosure is necessary to protect our rights, protect your safety
          or the safety of others, investigate fraud, or respond to a government request. </p>

        <h5>How we use your email address</h5>

        <p>We may at times share with you news, products, and events. In order to share with you, Canada’s anti- spam
          legislation (CASL) requires us to obtain your consent to receive electronic communications. When you provide
          us with your email address, we will then assume we have your consent to email you (unless you say otherwise).
          However, to ensure you want to receive our helpful emails, our emails will also have an "unsubscribe" option
          at the bottom. In addition, from time to time, we may ask you to confirm your consent to receive our emails.
        </p>

        <h5>Links</h5>

        <p>Wize.Health may contain links to, or advertisements about, non-Wize.Health websites. Other sites may also
          reference, advertise, or link to Wize.Health’s website. Wize.Health does not endorse or sponsor other websites, is
          not responsible for the privacy practices or the content of non-Wize.Health websites, expressly disclaims any
          statements or assertions made on such websites, and denies all liability associated with your use of, and the
          content on, such other websites and advertisements. Please be advised that the practices described in this
          Privacy Policy do not apply to information gathered through other websites. We encourage you to be aware of
          when you leave Wize.Health’s website and to read the privacy policies of

          each and every website that you visit. </p>

        <h5>Safeguarding Personal Information</h5>

        <p>All employees of Wize.Health, other suppliers, funders and service or information providers who need to have
          access to your personal information in order to assist us in providing you with the products and services you
          have requested, understand the need to keep this information protected and confidential. They know they are to
          use the information only for the purposes intended. Wize.Health will only provide personal information to third
          parties on an "as needed" basis and provided that such parties agree to be bound by Wize.Health's Privacy
          Policy. We have also established physical and systems safeguards, along with proper processes, to protect
          client information from unauthorized access or use. </p>

        <h5>Accuracy of Personal Information</h5>

        <p>Wize.Health also makes every effort to keep all personal information it holds, including contact information
          and financial information, accurate, complete, and current. Wize.Health will update any changes to personal
          information when received from you through the website. If Wize.Health holds personal information about an
          individual which can be established by the individual to be inaccurate, Wize.Health will take reasonable steps
          to correct it. </p>

        <h5>Consent</h5>

        <p>By using Wize.Health’s website and its services you consent to the uses indicated in this Privacy Policy.
          Should you chose, at any time, to withdraw your consent please immediately contact Wize.Health’s Privacy Officer
          in writing to advise of this decision. </p>

        <p>If Wize.Health requires personal information for a new purpose, it will seek consent for such new use. </p>

        <h5>Access to Personal Information</h5>

        <p>You have the ability to access all your personal information on the website at any time. If you have any
          questions regarding the personal information held by Wize.Health, you should, in writing, contact Wize.Health’s
          Privacy Officer. Wize.Health will respond to any written requests within 30 days of receiving written notice.
          Wize.Health will keep records of enquiries and/or objections and will document their resolution. Wize.Health will
          conduct all investigations in a timely, fair and impartial manner. </p>

        <h5>Changes to the Privacy Policy</h5>

        <p>Wize.Health reserves the right to modify or supplement this Privacy Policy at any time. If we make a change to
          this Privacy Policy, we will post such changes on the Privacy page of the website. Wize.Health will obtain the
          necessary consents required under applicable privacy laws if it seeks to collect, use or disclose your
          personal information for purposes other than those to which consent has been obtained unless otherwise
          required or permitted by law. </p>




      </div>
    </div>
  </div>
</section>