import { Component, OnInit, ElementRef, HostListener, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { GlobalConstants } from '../../shared/global-constants';
import { PrescriptionService } from '../../services/prescription.service';
import { Prescription } from '../../shared/models/prescription.model';
import { User } from '../../shared/models/user.model';

import { PrescriptionStatuses } from '../../shared/pipes/prescription-status.pipe'

import { environment } from '../../../environments/environment';
import { ThrowStmt } from '@angular/compiler';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.scss']
})
export class PrescriptionListComponent implements OnInit {

  columnsToDisplay = ['id', 'patientID', 'status',"patientNmae", 'prescriptionType'];
  prescriptionStatusEnum = PrescriptionStatuses;
  prescriptions: Array<Prescription> = [];
  currentPrescription: Prescription = {} as Prescription;
  refilePrescription: any;
  public Image_URL = environment.baseUrl + '/prescriptionimages/';

  constructor(private prescriptionService: PrescriptionService, private route: Router) {

    // if(GlobalConstants.isLoggedIn)
    // {
    $('.csLoader').css({ display: 'flex' });
    this.prescriptionService.getPrescriptionForUser(GlobalConstants.getcurrentUser()?.id!).subscribe(data => {
      this.prescriptions = data.data;
      console.log("Prescriptions=",this.prescriptions)
      $('.csLoader').hide();
    });
    // }
  }

  ngOnInit(): void {
  }

  cancel() {

    this.prescriptionService.cancel(this.currentPrescription.id).subscribe(result => {
      $('#submit-success').modal('toggle');
      this.currentPrescription.status = 1;
    }, error => {
      console.error(error);

    });
  }

  setCurrentPrescription(prescription: Prescription) {

    this.currentPrescription = prescription;
    // console.log("Prescription=",this.currentPrescription.pharmacy)
  }
  refilePrescriptionWitNote(isTransferredPrescription: boolean = false) {
    this.refilePrescription = { prescriptionType: 0, withNoteOnly: true, isTransferredPrescription: isTransferredPrescription };
    this.route.navigateByUrl('/prescription/prescription-upload', { state: this.refilePrescription });

  }
}
