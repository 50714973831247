import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';

import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { PrescriptionService } from '../services/prescription.service';
import { PharmacyService } from '../services/pharmacy.service';
import { AuthenticationService } from '../services/authentication.service';
import { DeSanitizeMobileNumber } from '../directives/DeSanitizeMobileNumber';

import { GlobalConstants } from '../shared/global-constants';
import { Prescription, PrescriptionPost, Address } from '../shared/models/prescription.model';
import { Pharmacy } from '../shared/models/pharmacy.model';
import { DatePipe } from '@angular/common';
import { User } from '../shared/models/user.model';
import { th } from 'date-fns/locale';

declare var Compress: any;
declare var $: any;

@Component({
  selector: 'mylocalrx-contentcontainer-prescriptions',
  templateUrl: './mylocalrx-contentcontainer-prescriptions.component.html',
  styleUrls: ['./mylocalrx-contentcontainer-prescriptions.component.scss'],
})
export class MylocalrxContentcontainerPrescriptionsComponent implements OnInit {

  compress = new Compress();
  pharmacies: Array<Pharmacy> | undefined;
  addresses: Array<Address> | undefined;
  selectedPharmacy: Pharmacy | undefined;
  selectedPharmacyID: string = "";
  selectedDeliveryType: number = -1;
  displaySelfPickUp: boolean = true;
  selectedPharmacyAddress: string = "";
  selectedPharmacyAddress2: string = "";
  selectedPharmacyContact: string = "";
  selectedPharmacyTiming: string = "";
  newPrescription: any = null;
  refillPrescription: any;

  withNoteOnly = false;
  isTransferredPrescription = false;
  listMembers: User[] = [];
  currentUser = GlobalConstants.getcurrentUser();
  constructor(private pharmacyService: PharmacyService,
    private zone:NgZone,
    private userService: AuthenticationService,
    private prescriptionService: PrescriptionService, private datePipe: DatePipe,
    private deSanitizeMobileNumber: DeSanitizeMobileNumber,
    private router: Router) {
    this.newPrescription = {
      isDeliveryAddressNew: -1,
      patientID: "",
      pharmacyID: this.currentUser?.preferredPharmacy,
      //patientHasInsurance:false,
      prescriptionType: 0,
      medicineTakenBefore: false,
      deliveryAddress: {
        firstName: '',
        lastName: '',
        unitNo: '',
        buildingName: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: ''
      },
      noteFromPatient: '',
      refill: false,
      prefferdDeliveryTime: "",
      outerPharmacyName: "",
      outerPharmacyNumber: "",
      isTransferredPrescription: false,
      repeateInDays: "",
      repeatable: false,
      nextDeliveryDate: ''
    }
    if (this.router.getCurrentNavigation()!.extras.state) {
      this.refillPrescription = this.router.getCurrentNavigation()!.extras.state;
      this.refillPrescription.isDeliveryAddressNew = -1;
      this.withNoteOnly = this.router.getCurrentNavigation()!.extras.state!.withNoteOnly ? true : false;
      this.isTransferredPrescription = this.router.getCurrentNavigation()!.extras.state!.isTransferredPrescription ? true : false;
      this.refillPrescription.isTransferredPrescription = this.isTransferredPrescription;
      if (!this.refillPrescription.pharmacyID) {
        this.refillPrescription.pharmacyID = this.currentUser?.preferredPharmacy;
      }
      if (!this.refillPrescription.deliveryAddress) {
        this.refillPrescription.deliveryAddress = {
          firstName: '',
          lastName: '',
          unitNo: '',
          buildingName: '',
          streetAddress: '',
          city: '',
          country: '',
          postalCode: ''
        }
      }
    }
  }

  ngOnInit(): void {
    this.userService.getUserAddresses(this.currentUser?.id!).subscribe(data => {
      this.addresses = data ? data.address : null;

    });
    this.pharmacyService.getAllMyLocarxEnable(true).subscribe(data => {
      this.pharmacies = data.filter(x => x.myLocalRXEnabled == true);;

      if (this.refillPrescription) {
        this.newPrescription = this.refillPrescription;
        this.newPrescription.refill = true;

        this.newPrescription.repeatable = false;
        this.newPrescription.repeateInDays = "";

        this.newPrescription.prescriptionLinks = this.refillPrescription.prescriptionLinks;
      }

      if (this.pharmacies !== undefined && this.pharmacies.length > 0) {
        if (!this.newPrescription.pharmacyID) {
          this.newPrescription.pharmacyID = this.pharmacies[0].id;
        }
        var selected = this.pharmacies.find(x => x.id == this.newPrescription.pharmacyID)!;
        if (!selected) {
          selected = this.pharmacies[0];
          this.newPrescription.pharmacyID = selected.id;
        }
        if (selected) {
          this.selectedPharmacy = selected;
          this.selectedPharmacyID = selected.id;
          this.selectedPharmacyAddress = selected.name + '\n <br>' + selected.add1 + '\n ';
          this.selectedPharmacyAddress2 = selected.city + ', ' + selected.state + ' - ' + selected.pin;
          this.selectedPharmacyContact = selected.phone;

          this.onPharmacySelect();
        }
      }

      this.fileUploadRquired = !this.newPrescription.refill;
      if (this.fileUploadRquired) {
        $(document).ready(function () {
          console.log('initializing FILEINPUT')
          $("#input-b5").fileinput({
            required: true,
            showCaption: false,
            dropZoneEnabled: false,
            showUpload: false,
            maxFileCount: 5,
            allowedFileTypes: ['image'],    // allow only images
            showCancel: false,
            initialPreviewAsData: true,
            browseLabel: 'Upload Prescription...',
            msgFileRequired: 'You must select a prescription to upload.'
          });
        });
      }

    });

    this.listMembers.push(this.currentUser as User);
    this.userService.findByParentId(this.currentUser?.id).subscribe(x => {
      console.log(x);
      if (x) {
        this.listMembers.push(...x);
      }
      setTimeout(() => {

        this.newPrescription.patientID = this.currentUser?.id;
        this.newPrescription.patientFullName = this.currentUser?.firstName + " " + this.currentUser?.lastName
        console.log(this.newPrescription.patientID);
      }, 1000);
    });
  }
  fileUploadRquired = true;
  deliverySlots: any[] = [];
  userSelectPharmacy: Pharmacy = {} as Pharmacy;
  onPharmacySelect(): void {
    var pharmacy = this.pharmacies!.find(x => x.id == this.newPrescription.pharmacyID)!;
    this.userSelectPharmacy = pharmacy;
    this.selectedPharmacyAddress = pharmacy.name + ', \n' + pharmacy.add1 + ',';
    this.selectedPharmacyAddress2 = pharmacy.city + ', ' + pharmacy.state + ' - ' + pharmacy.pin;
    this.selectedPharmacyContact = this.deSanitizeMobileNumber.transform(pharmacy.phone, true);
    this.deliverySlots = pharmacy.deliverySlots;
    var time = parseInt(this.datePipe.transform(new Date(), "HH")!);

    if (this.deliverySlots) {
      this.deliverySlots.forEach(element => {
        var t = parseInt(element.startTime.substring(0, 2)) - 2;
        element.disable = t < (time);
      });
    }
    // if (this.deliverySlots && this.deliverySlots.filter(x => x.disable == false).length == 0) {

    //   this.deliverySlots.push(nextDay);
    //   if (this.deliverySlots.length > 3) {
    //     this.deliverySlots.splice(0, 1);
    //   }

    // }
    // console.log(pharmacy);
  }
  onMemberSelect() {
    var selectUser = this.listMembers.find(x => x.id == this.newPrescription.patientID);
    console.log(selectUser);
    this.newPrescription.patientFullName = selectUser?.firstName + " " + selectUser?.lastName;
    this.newPrescription.parentPatientID = selectUser?.parentId ? selectUser?.parentId : "";
  }

  changePrescriptionType(e: any) {
    console.log(e.target.value);
    this.selectedDeliveryType = e.target.value;
    if (this.selectedDeliveryType == 0) {
      this.displaySelfPickUp = true;
    } else {
      this.displaySelfPickUp = false;
      if (this.addresses && this.addresses.length > 0) {
        this.newPrescription.isDeliveryAddressNew = 0;
        this.AddressChanged();
      }
    }
  }
  AddressChanged() {
    if (this.newPrescription.isDeliveryAddressNew > -1) {
      this.newPrescription.deliveryAddress = this.addresses![this.newPrescription.isDeliveryAddressNew];
    }
    else {
      this.newPrescription.deliveryAddress = {
        firstName: '',
        lastName: '',
        unitNo: '',
        buildingName: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: ''
      };
    }
  }
  developerError = "";
  save() {

    let prescriptionFileInput = this.fileUploadRquired ? this.input_b5 : [];
    var currentDate = new Date();
    var prescription = {
      prescriptionType: this.newPrescription.prescriptionType,
      medicineTakenBefore: this.newPrescription.medicineTakenBefore,
      purposeOfMedicine: this.newPrescription.purposeOfMedicine,
      allergyInfo: this.newPrescription.allergyInfo,
      patientID: this.newPrescription.patientID,
      pharmacyID: this.newPrescription.pharmacyID,
      parentPatientID: this.newPrescription.parentPatientID,
      refill: this.newPrescription.refill,
      prescriptionLinks: this.newPrescription.prescriptionLinks,
      prefferdDeliveryTime: this.newPrescription.prefferdDeliveryTime,
      prefferdDeliveryDate: this.datePipe.transform(new Date(currentDate.getTime() + (currentDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
      deliveryAddress: this.newPrescription.prescriptionType >= 1 ? this.newPrescription.deliveryAddress : null,
      noteFromPatient: this.newPrescription.noteFromPatient,
      patientFullName: this.newPrescription.patientFullName,
      distance: this.newPrescription.distance ? this.newPrescription.distance : "N/A",
      isDeliveryAddressNew: this.newPrescription.prescriptionType == 0 ? false : this.newPrescription.isDeliveryAddressNew == -1,
      createdBy: this.currentUser?.id,
      isTransferredPrescription: this.isTransferredPrescription,
      outerPharmacyNumber: this.deSanitizeMobileNumber.transform(this.newPrescription.outerPharmacyNumber),
      outerPharmacyName: this.newPrescription.outerPharmacyName,
      repeatable: this.newPrescription.repeatable,
      repeateInDays: this.newPrescription.repeatable ? this.newPrescription.repeateInDays : 0,
      nextDeliveryDate: ""
    }

    if (prescription.prefferdDeliveryTime && prescription.prefferdDeliveryTime.startsWith("n_", 0)) {
      prescription.prefferdDeliveryTime = prescription.prefferdDeliveryTime.replace("n_", "");
      currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      prescription.prefferdDeliveryDate = this.datePipe.transform(new Date(currentDate.getTime() + (currentDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");
    }
    if (this.newPrescription.repeatable && this.newPrescription.repeateInDays) {
      currentDate.setDate(currentDate.getDate() + parseInt(this.newPrescription.repeateInDays));
      prescription.nextDeliveryDate = this.datePipe.transform(new Date(currentDate.getTime() + (currentDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS")!;
    }
    var pharmacyAddress = this.userSelectPharmacy.name + " " + this.userSelectPharmacy.add1 + " " + this.userSelectPharmacy.add2 + " " + this.userSelectPharmacy.pin
    var userAddress = this.newPrescription.deliveryAddress.streetAddress + " " + this.newPrescription.deliveryAddress.city + " " + this.newPrescription.deliveryAddress.postalCode;

    this.UploadPrescription(prescription, prescriptionFileInput);
  }

  private UploadPrescription(prescription: any, prescriptionFiles: any) {
    $('.csLoader').css({ display: 'flex' });
    this.prescriptionService.save(prescription, prescriptionFiles).subscribe(result => {
      $('#submit-success').modal('toggle');
      $('.csLoader').hide();

    }, error => {
      $('.csLoader').hide();
      console.error(error);
      this.developerError = JSON.stringify(error);
      $("#developerError").modal('toggle');
    });
  }

  showList() {
    this.router.navigateByUrl('prescription/prescription-list');
  }

  confirmationText = "";
  TriggerModal(prescriptionForm: NgForm, modalId: string) {
    this.confirmationText = "Are you sure you want to submit your prescription?";
    if (this.fileUploadRquired && $('#input-b5')[0].files.length <= 0) {
      return;
    }
    var firstInvalid = false;
    if (this.withNoteOnly) {
      var control = prescriptionForm.form.controls["noteFromPatient"];
      if (!control.value || control.value.length < 2) {
        control.markAsTouched();
        $('#noteFromPatient').focus();
        return;
      }

      if (this.isTransferredPrescription) {
        var control = prescriptionForm.form.controls["outerPharmacyName"];
        if (control.invalid) {
          control.markAsTouched();
          $('#outerPharmacyName').focus();
          return;
        }

        control = prescriptionForm.form.controls["outerPharmacyNumber"];
        if (control.invalid) {
          control.markAsTouched();
          $('#outerPharmacyNumber').focus();
          return;
        }
      }
    }
    if (this.newPrescription.prescriptionType >= 1 && prescriptionForm.form.invalid) {
      for (const field in prescriptionForm.form.controls) {
        console.log(field);
        const control = prescriptionForm.form.get(field);
        if (control?.invalid && !firstInvalid) {
          control.markAsTouched();
          $('#' + field).focus();
          if (field == "prefferdDeliveryTime") {
            $('html, body').animate({
              scrollTop: $('#prefferdDeliveryTime').offset().top
            }, 'slow');

            $("#prefferdDeliveryTime").addClass("error"); //change second element background
            setTimeout(function () {
              $("#prefferdDeliveryTime").removeClass("error"); // change it back after ...
            }, 3000);
          }
          firstInvalid = true;
        }
      }
      return;
    }
    else {

      var control = prescriptionForm.form.controls["purposeOfMedicine"];
      if (control?.invalid && !firstInvalid) {
        control.markAsTouched();
        $('#purposeOfMedicine').focus();
        return;
      }
      var control = prescriptionForm.form.controls["allergic"];
      if (control?.invalid && !firstInvalid) {
        control.markAsTouched();
        $('#allergic').focus();
        return;
      }
    }

    var pharmacyAddress = this.userSelectPharmacy.name + " " + this.userSelectPharmacy.add1 + " " + this.userSelectPharmacy.add2 + " " + this.userSelectPharmacy.pin
    var userAddress = this.newPrescription.deliveryAddress.streetAddress + " " + this.newPrescription.deliveryAddress.city + " " + this.newPrescription.deliveryAddress.postalCode;

    if (this.newPrescription.prescriptionType >= 1) {

      $('.csLoader').css({ display: 'flex' });
      this.prescriptionService.findAddressRoute(pharmacyAddress, userAddress).then(response => {

        $('.csLoader').css({ display: 'none' });
        if (response.rows && response.rows[0].elements) {
          var distance = response.rows[0].elements[0].distance.value;
          this.newPrescription.distance = distance;
          if (this.newPrescription.prescriptionType >= 2) {
            this.validateDistance(this.newPrescription.distance);
          }
          else {
            $('#' + modalId).modal('toggle');
          }
        }
      }).catch(x => {
        $('.csLoader').css({ display: 'none' });
        this.newPrescription.distance = 5000;
        $('#confirm-submit').modal('toggle');
        //$('#deliveryNotAvialble').modal('toggle');
      });
    }
    else {
      $('#' + modalId).modal('toggle');
    }
  }
  distanceCharge = [{ min: 0, max: 5000, charge: 10 }, { min: 5000, max: 20000, charge: 15 }, { min: 20000, max: 35000, charge: 25 }];
  validateDistance(distance: number) {
    var charge = this.distanceCharge.filter(x => distance > x.min && distance <= x.max).map(x => { return x.charge });
    if (charge) {
      var km = (distance / 1000).toFixed(2);
      this.confirmationText = `Express delivery will cost you extra for ${km} km.<br/>Are you sure you want to submit your prescription?`;
      $('#confirm-submit').modal('toggle');
    }
    else {
      // $('#deliveryNotAvialble').modal('toggle');
      this.newPrescription.distance = 5000;
      $('#confirm-submit').modal('toggle');
    }
  }

  removePrescriptionLinks(index: number) {
    this.newPrescription.prescriptionLinks.splice(index, 1);
  }


  input_b5: any[] = [];
  fileChangeEvent(event: any, id: string) {
    this.input_b5 = [];
    if (event.target.files) {
      const files1 = [...event.target.files]
      this.compress.compress(files1, {
        size: 4,
        quality: .75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true,
      }).then((data: any[]) => {
        console.log(data);
        var newFiles: any = [];
        data.forEach(element => {
          newFiles.push(this.base64ToFile(element.prefix + element.data, new Date().getTime() + ".jpeg"));
        });
        if (id === "input-b5") {
          this.input_b5 = newFiles;
        }
      })
    }
  }

  base64ToFile(dataURI: any, fileName: any): File {

    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], fileName, { type: mimeString });
  }
  googleAddressSelected(event:Address){
   this.zone.run(()=>{
     this.newPrescription.deliveryAddress.streetAddress = event.streetAddress;
     this.newPrescription.deliveryAddress.postalCode = event.postalCode;
     this.newPrescription.deliveryAddress.city = event.city;
   });
  }
  addressToDelete = -1;
  deleteAddress(i: number) {
    this.addressToDelete = i;
    $('#delAddressConfirmation').modal('toggle');
  }
  confirmDeleteAddress() {
    this.userService.deleUserAddresses(this.currentUser?.id, this.addressToDelete).subscribe(x => {
      
      this.addresses?.splice(this.addressToDelete, 1);
    });
  }
}