<footer class="new_footer_area bg_color py-5 mt-10">
  <!-- <div class="new_footer_top">
    <div class="container footerMo">
      <div class="row">
        <div class="col-md-3">
          <div class="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s" style="
              visibility: visible;
              animation-delay: 0.4s;
              animation-name: fadeInLeft;
            ">
            <h3 class="f-title f_600 t_color f_size_18">Wize Health</h3>
            <ul class="list-unstyled f_list">
              <li>
                <a [routerLink]="[
                    'prescription/prescription-list'
                  ]">Prescription</a>
              </li>
             
              <li>
                <a [routerLink]="[
                    'appointment/covid'
                  ]">Covid Appointment</a>
              </li>
              <li>
                <a [routerLink]="['MylocalrxContentcontainerServiceComponent']">Service</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3">
          <div class="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.6s" style="
              visibility: visible;
              animation-delay: 0.6s;
              animation-name: fadeInLeft;
            ">
            <h3 class="f-title f_600 t_color f_size_18">Explore</h3>
            <ul class="list-unstyled f_list">
              <li>
                <a [routerLink]="['MylocalrxContentcontainerAboutusComponent']">About Us</a>
              </li>
              <li>
                <a [routerLink]="['MylocalrxContentcontainerContactusComponent']">Contact Us</a>
              </li>
              <li>
                <a [routerLink]="['MylocalrxTermsandconditionsComponent']">Term &amp; conditions</a>
              </li>
              <li>
                <a [routerLink]="['MylocalrxPrivacypolicyComponent']">Privacy</a>
              </li>
              <li>
                <a [routerLink]="['MylocalrxConsentComponent']">Consent</a>
              </li>
              <li>
                <a [routerLink]="['MylocalrxContentcontainerFaqsComponent']">FAQ</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 fullOnMo">
          <div class="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.8s" style="
              visibility: visible;
              animation-delay: 0.8s;
              animation-name: fadeInLeft;
            ">
            <h3 class="f-title f_600 t_color f_size_18">Follow Us</h3>
            <div class="f_social_icon">
              <a href="https://www.facebook.com/Mylocalrx-105498504839939/?view_public_for=105498504839939"
                class="fa fa-facebook" target="_blank"></a>
             
              <a href="#" class="fa fa-instagram"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_bg">
      <div class="footer_bg_one"></div>
      <div class="footer_bg_two"></div>
    </div>
  </div> -->
  <div class="footer_bottom">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-sm-7">
          <p class="mb-0 f_400">
            © Wize.Health 2023 All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>