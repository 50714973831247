<!-- <div class="container">
    <div class="row">
        <div class="col-md-12" style="padding-top: 20px; padding-bottom: 10px;">
            <img style="width: 135px;" src="../../assets/svg/MyLocalRxLogo-full.svg" />
            <div style="width: auto; float: right;">
                <span>
                    <img src="../../assets/new/uploadRx.svg" style="width: 15px;    margin-top: -5px;" />
                    <label
                        style="margin-left: 10px; color: var(--gray);  font-family: var(--font-family-noto_sans);  font-size: var(--font-size-s);">Upload
                        Rx</label>
                </span>
                <span>
                    <img src="../../assets/new/qt.svg" style="width: 20px; margin-left: 30px;" />
                    <label
                        style="margin-left: 10px; color: var(--gray);  font-family: var(--font-family-noto_sans);  font-size: var(--font-size-s);">FAQs</label>
                </span>
                <span>
                    <img src="../../assets/new/lgsg.svg" style="width: 18.54px; margin-left: 30px;" />
                    <label
                        style="margin-left: 10px; color: var(--red); font-family: var(--font-family-noto_sans);   font-size: var(--font-size-s);">
                        <a>Sign In</a>/<a>Sign Up</a>
                    </label>
                </span>
            </div>
        </div>
    </div>
</div> -->
<!-- <div style="width: 100%; display: table;    background-color: #636363e6;    color: var(--white);
    font-family: var(--font-family-roboto);  font-size: var(--font-size-s);padding-top: 6px;padding-bottom: 4px;">
    <marquee>Pfizer and Moderna vaccination appointments now available at most of the pharmacies. Please do not call
        Pharmacies for
        Walk In Appointments.</marquee>
</div> -->

<meta name="viewport" content="width=device-width, initial-scale=1.0">
<div class="container" style="z-index: 99;">
    <div style="height: 60px;width: 100%;"></div>
    <div class="row">
        <div class="col-md-6">
            <label
                style="margin: 0; color: var(--blue-chill);  font-family: var(--font-family-noto_sans); font-size: 20.1px;width: 100%;">Stay
                Home Stay Safe</label>

            <label
                style="    color: #a1a1a1; font-weight: normal; font-family: var(--font-family-noto_sans);   font-size: 17.2px; width: 100%;">Get
                your prescribed medication delivered to your doorstep with Wize Health.</label>
            <div class="row">
                <div class="col-md-6">
                    <a routerLink="prescription">
                        <div class="orsc">
                            <img class="custom1"  style="width: 30px;" src="../../assets/new/psDelivery.svg" />
                            <span>Prescription Delivery</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-6"><a href="#" target="_blank">
                        <div class="orsc"> <img style="width: 26px;" src="../../assets/new/speak.svg" />
                            <span>Speak to a Doctor</span>
                        </div>
                    </a>
                </div>
                <!-- <div class="col-md-6">
                    <a [routerLink]="['/appointment/covid/vaccination']">
                        <div class="orsc">
                            <img style="width: 25px;" src="../../assets/new/vaccination.svg" />
                            <span>Book Covid-19 Vaccination</span>
                        </div>
                    </a>
                </div> -->
             


                <div style="z-index: 99;" class="col-md-6" >
                    <div (click)="showUl1()" class="orsc t21s1">
                        <img style="width: 25px;" src="../../assets/new/appt.svg" />
                        <span>Book an Appointment</span>
                        <ul>
                            <li [routerLink]="['/appointment/covid/vaccination']" data-toggle="modal" (click)="serviceName('Covid Vaccine')"
                                data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;;">
                                Covid Vaccine
                                <img src="../../assets/new/arrow_n.svg" style="float: right;" />
                            </li>
                            <li [routerLink]="['/appointment/covid/flushot']" data-toggle="modal" (click)="serviceName('Flu Shot')"
                                data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;"> Flu
                                shot<img src="../../assets/new/arrow_n.svg" style="float: right;" /></li>
                            <li [routerLink]="['/appointment/covid/shingrix']" data-toggle="modal" (click)="serviceName('Shingrix Vaccine')"
                                data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;"> Shingrix Vaccine
                                <img src="../../assets/new/arrow_n.svg" style="float: right;" /></li>
                            <li [routerLink]="['/appointment/covid/cannabis']" (click)="serviceName('Cannabis Consultation')"> Cannabis Consultation
                                <img src="../../assets/new/arrow_n.svg" style="float: right;" />
                            </li>
                        </ul>
                    </div>
                </div>

                <div style="z-index: 50;" class="col-md-6" >
                    <div (click)="showUl()" class="orsc t21s">
                        <img style="width: 25px;" src="../../assets/new/appt.svg" />
                        <span>Pharmacy Consultation</span>
                        <ul>
                            <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal" (click)="serviceName('Allergic Runny nose')"
                                data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;;">
                                Allergic Runny nose
                                <img src="../../assets/new/arrow_n.svg" style="float: right;" />
                            </li>

                            <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal" (click)="serviceName('Bacterial')"
                                data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;"> Bacterial or viral Eye infections and allergic Itchy eyes<img src="../../assets/new/arrow_n.svg" style="float: right;" /></li>
                            <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal" (click)="serviceName('Cold sores')"
                                data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;">Cold sores
                                <img src="../../assets/new/arrow_n.svg" style="float: right;" /></li>
                            <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal" (click)="serviceName('Heartburn')"
                            data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;"> Heartburn
                                <img src="../../assets/new/arrow_n.svg" style="float: right;" />
                            </li>
                            <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal"(click)="serviceName('Hemorrhoids')"
                            data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;;">
                            Hemorrhoids
                            <img src="../../assets/new/arrow_n.svg" style="float: right;" />
                        </li>
                        <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal" (click)="serviceName('Impetigo')"
                            data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;">Impetigo
                            <img src="../../assets/new/arrow_n.svg" style="float: right;" /></li>
                        <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal" (click)="serviceName('Insect bites and hives')"
                            data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;">Insect bites and hives
                            <img src="../../assets/new/arrow_n.svg" style="float: right;" /></li>
                        <li [routerLink]="['/appointment/covid/minorAilments']"   data-toggle="modal" (click)="serviceName('Menstrual cramps')"
                        data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;"> Menstrual cramps
                            <img src="../../assets/new/arrow_n.svg" style="float: right;" />
                        </li>

                        <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal" (click)="serviceName('Musculoskeletal sprains and strains')"
                        data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;;">
                        Musculoskeletal sprains and strains
                        <img src="../../assets/new/arrow_n.svg" style="float: right;" />
                    </li>
                    <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal" (click)="serviceName('Oral thrush')"
                        data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;">Oral thrush
                        <img src="../../assets/new/arrow_n.svg" style="float: right;" /></li>
                    <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal"(click)="serviceName('Skin rashes')"
                        data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;">Skin rashes (atopic, eczema, allergic and contact)
                        <img src="../../assets/new/arrow_n.svg" style="float: right;" /></li>
                    <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal" (click)="serviceName('Tick bites')"
                    data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;"> Tick bites, post-exposure prophylaxis to prevent Lyme disease
                        <img src="../../assets/new/arrow_n.svg" style="float: right;" />
                    </li>
                    <li [routerLink]="['/appointment/covid/minorAilments']" data-toggle="modal" (click)="serviceName('Urinary tract infections')"
                    data-target="#asymp-confirmation" style="border-bottom:  solid 1px #e1e1e1;" >Urinary tract infections
                        <img src="../../assets/new/arrow_n.svg" style="float: right;" />
                    </li>
                        </ul>
                    </div>
                </div>

                
                <!-- <div style="z-index: 9;" class="col-md-6">
                    <a [routerLink]="['/appointment/covid/flushot']">
                        <div class="orsc">
                            <img style="width: 25px;" src="../../assets/new/vaccination.svg" />
                            <span>Book Flu shot</span>
                        </div>
                    </a>
                </div> -->
            </div>
        </div>
        <div class="col-md-6">
            <!-- <img src="../../assets/svg/img 1.svg" /> -->
            <img style="width:390px; margin-left:0px"  src="../../assets/images/home.jpg" />
        </div>
    </div>
</div>
<hr>
<section class="py-5 py-md-11 border-bottom" style="background-color:#f6f6f6 ;">


<div class="container" >
    <div class="row">
        <div style="width: 100%;display: flex;    justify-content: space-around;margin-top: 40px;">
            <div style="text-align: center;">
                 <h2>PHARMACY</h2>
              </div>                 
      </div>
    </div>
    <div class="row">
        <div style="width: 100%;display: flex;    justify-content: space-around;margin-top: 0px;">
            <div style="text-align: center;">
                <h4>Follow These 3 Simple Steps                    </h4>
             </div>
             </div> 
    </div>
    <br>
    <div class="row">   
      <div class="col-md" >
        <img src="./../../assets/images/uploadPrescription.jpg" width="320px" />
        <h4 style="margin-top: 20px; text-align: center;">UPLOAD YOUR PRESCRIPTION TODAY</h4>
      </div>
      <div class="col-md">
        <img src="./../../assets/images/shipping.jpg" width="320px" />
            <h4 style="margin-top: 20px; text-align: center;">ENTER YOUR SHIPPING/PICK UP DETAILS</h4>
      </div>
      <div class="col-md">
        <img src="./../../assets/images/dedlivery.jpg" width="320px" />
        <h4 style="margin-top: 20px; text-align: center;">RELAX AS WE DELIVER TO YOUR DOORSTEP</h4>
      </div>
    </div>
    <div class="row" style="text-align:center ; margin-left:43%; margin-top: 40px;" >
        <a href="https://pharmacy-apps.wize.health/prescription/prescription-list" class="btn btn-primary">Upload Now
        </a>
    </div>
  </div>
  </section>
<!-- <section class="py-5 py-md-11 border-bottom">
   

    <div style=" width: 100%; display: table;margin-top: 10px; background:#f5f5f5">
        <div style="width: 100%;display: flex;    justify-content: space-around;margin-top: 40px;">
              <div style="text-align: center;">
                   <h2>PHARMACY</h2>
                </div>
               
        </div>
        <div style="width: 100%;display: flex;    justify-content: space-around;margin-top: 0px;">
        <div style="text-align: center;">
            <h4>Follow These 3 Simple Steps                    </h4>
         </div>
         </div>
      <div class="container">
          <div style="width: 100%;display: flex;    justify-content: space-around;margin-top: 20px;">
              <div style="text-align: center;">
                <img src="./../../assets/images/uploadPrescription.jpg" width="320px" />
             <h4 style="margin-top: 20px;">UPLOAD YOUR PRESCRIPTION <br/>TODAY</h4>
                  
     </div>
     <div style="text-align: center;">
        <img src="./../../assets/images/shipping.jpg" width="320px" />
            <h4 style="margin-top: 20px;">ENTER YOUR SHIPPING/PICK UP <br/>DETAILS</h4>
     </div>
              <div style="text-align: center;">
                <img src="./../../assets/images/dedlivery.jpg" width="320px" />
       <h4 style="margin-top: 20px;">RELAX AS WE DELIVER TO YOUR <br/>DOORSTEP</h4>
              </div>
          </div>
          <div style="text-align: center;margin-top: 20px;">
          <a href="https://pharmacy-apps.wize.health/prescription/prescription-list" class="btn btn-primary">Upload Now
            </a>
            </div>
            <br/><br/>
      </div>
    </div>
</section> -->


<div
    style="z-index: 9; width: 100%;background: linear-gradient(90.85deg, #232323 -3.32%, #454545 30.89%, #4E4E4E 40.94%, #5E5E5E 56.43%, #8F8F8F 107.3%); display: table;margin-top: 30px;">
    <div class="container">
        <div style="width: 100%;display: flex;    justify-content: space-around;">
            <div style="text-align: center;">
                <a [routerLink]="['/register']">
                    <img style="height: 70px; margin-top: 10px;" src="../../assets/new/register.svg" /><br />
                    <label style="color: white; font-size: 12px;">Register</label>
                </a>
            </div>
            <div style="text-align: center;">
                <a [routerLink]="['/prescription']">
                    <img style="height: 70px; margin-top: 10px;" src="../../assets/new/selectPh.svg" /><br />
                    <label style="color: white; font-size: 12px;">Choose Pharmacy</label>
                </a>
            </div>
            <div style="text-align: center;">
                <a [routerLink]="['/prescription']">
                    <img style="height: 70px; margin-top: 10px;" src="../../assets/new/rcvmedi.svg" /><br />
                    <label style="color: white; font-size: 12px;">Recieve Medications</label>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- <section class="py-5 py-md-11 border-bottom">
    <app-home-list-products [isHomePage]="true"></app-home-list-products>
</section> -->
<div class="container">
    <div style="height: 60px;width: 100%;"></div>
    <div class="row">
        <div class="col-md-6">
            <img src="../../assets/svg/img 9.svg" />
        </div>
        <div class="col-md-6">
            <h3 style="color: var(--chicago);   font-family: var(--font-family-noto_sans);   font-size: 34.1px;">
                About Us
            </h3>
            <label
                style="color: var(--black); font-weight: 600;  font-family: var(--font-family-noto_sans);  font-size: var(--font-size-l);  font-size: 25px ;">Stay
                Home  Stay Safe</label>
            <!-- <label
                style="    color: #ababab; font-family: var(--font-family-noto_sans);  font-size: var(--font-size-l);   font-style: normal;  font-weight: 400;">
                Wize.Health is the only patient focused platform created to enable better access to Healthcare services to patients and to give Healthcare providers greater access to patients by putting them on a single platform.
            </label> -->
             <label
                style="color: var(--gray);  font-family: var(--font-family-noto_sans); font-size: var(--font-size-xs); font-size: 20px ;">
                Wize.Health is the only patient focused platform created to enable better access to Healthcare services to patients and to give Healthcare
                 providers greater access to patients by putting them on a single platform.
            </label>
        <!--    <label
                style="margin-top: 5px; color: var(--red);  font-family: var(--font-family-noto_sans); font-size: var(--font-size-xs);">
                <span
                    style="text-align: center; margin-right: 5px; display: inline-block; width: 20px; height: 20px; border: solid 1px var(--red); border-radius: 50%;">
                    2
                </span>
                Select your pharmacy of choice.
            </label>
            <label
                style="margin-top: 5px;color: var(--red);  font-family: var(--font-family-noto_sans); font-size: var(--font-size-xs);">
                <span
                    style="text-align: center; margin-right: 5px; display: inline-block; width: 20px; height: 20px; border: solid 1px var(--red); border-radius: 50%;">
                    3
                </span>
                Once your prescription is ready you get a notification that you can go and collect it or get it
                delivered.
            </label>
            <label
                style="margin-top: 10px;margin-left: 25px;color: var(--gray); font-family: var(--font-family-noto_sans);   font-size: var(--font-size-xxs);">Along
                with this, all our member pharmacies offer appointments for individual medication consultations, flu
                shots,
                vaccinations (including COVID once available). Along the way you will get timely updates via text
                message, and
                if you
                choose to get the medication delivered to you then you can track your medications progress to your
                house.</label> -->
            <!-- <label
                style="margin-top: 15px; float: right; color: var(--blue-chill);  font-family: var(--font-family-roboto);   font-size: 14.6px;  font-style: normal;">Learn
                More About MyLocalRX <img style="width: 42px;" src="../../assets/new/learMore.svg" /> </label> -->
        </div>
    </div>
    <br> <br> <br> <br> <br> <br> <br>

    <!-- <div style="height: 60px;width: 100%;"></div>
    <div class="row">
        <div class="col-md-6">
            <h3 style="color: var(--chicago);   font-family: var(--font-family-noto_sans);   font-size: 34.1px;">
                FAQs
            </h3>
            <div style="width: 100%; margin-top: 20px; display: table;    border: 0.7px solid var(--mercury);">
                <div class="faqh">
                    Do I need a valid prescription to get my medicine?
                    <img src="../../assets/new/pill.svg" style="position: absolute;top: 10px; right: 10px;" />
                </div>
                <div class="faqc">
                    Yes. You will have to upload a valid prescription to buy medicines using the MyLocalRX web app,
                    please note that the
                    original prescriptipon must be received by the pharmacy either by faq from the doctors office or
                    persocally form you
                    before any medication can be released for pick-up or delivery. In case you have misplaced your
                    prescription, our doctors
                    can help you via virtual consultation.
                </div>
            </div>
            <div style="width: 100%; margin-top: 20px; display: table;    border: 0.7px solid var(--mercury);">
                <div class="faqh">
                    Is there a fee for delivery?
                    <img src="../../assets/new/pill.svg" style="position: absolute;top: 10px; right: 10px;" />
                </div>
                <div class="faqc">
                    Delivery by the end of the day is free, but express delivery (within 2 hours) is a minimum of
                    $10 if
                    within 5kms of the
                    pharmacy, or more of you live further away.
                </div>
            </div>
            <div style="width: 100%; margin-top: 20px; display: table;    border: 0.7px solid var(--mercury);">
                <div class="faqh">
                    Where do you deliver to?
                    <img src="../../assets/new/pill.svg" style="position: absolute;top: 10px; right: 10px;" />
                </div>
                <div class="faqc">
                    At the moment we deliver in Brampton,Caledon and Mississauga only, but we hope to expand to the
                    GTA
                    soon.
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <img src="../../assets/svg/img 3.svg" />

        </div>
    </div>

</div>

<section class="pt-4 pt-md-11 py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-5 col-lg-6 order-md-1">
         
                <img src="./../../assets/svg/img 4.svg"
                    class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate" alt="..." data-aos="fade-up"
                    data-aos-delay="100" width="100%" height="100%" />
            </div>
            <div class="col-12 col-md-7 col-lg-6 order-md-2 aos-init aos-animate" data-aos="fade-up">
             
                <h3 style="color: var(--chicago);   font-family: var(--font-family-noto_sans);   font-size: 34.1px;">
                    Contact Us
                </h3>
                <div style="font-family: var(--font-family-noto_sans);font-size: 14px; font-weight: normal;"
                    class="well well-sm">
                    <form style="border: none !important;" class="form-horizontal querForm" #webQueryForm="ngForm"
                        (ngSubmit)="saveQuery(webQueryForm)">
                        <fieldset>
                      
                            <div class="form-group">
                                <div>
                                    <input [(ngModel)]="queryForm.name" id="name" name="name" #name type="text"
                                        placeholder="Enter your name" class="form-control" required pattern=".{3,}" />
                                    <label class="control-label" for="name">Name <span>*</span>
                                    </label>
                                </div>
                            </div>

                       
                            <div class="form-group">
                                <div>
                                    <input [(ngModel)]="queryForm.email" id="email" name="email" #email type="text"
                                        placeholder="Enter your E-mail address" class="form-control" required
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                                    <label class="control-label" for="email">E-mail Address <span>*</span></label>
                                </div>
                            </div>

                         
                            <div class="form-group">
                                <div>
                                    <input [(ngModel)]="queryForm.mobileNum" id="mobileNum" name="mobileNum" #mobileNum
                                        type="text" placeholder="Enter your mobile number" class="form-control"
                                        phoneNumber appPhoneMask required minlength="16" maxlength="17" />
                                    <label class="control-label" for="email">Mobile Number <span>*</span></label>
                                </div>
                            </div>

                           
                            <div class="form-group">
                                <div>
                                    <textarea [(ngModel)]="queryForm.message" class="form-control" #message id="message"
                                        name="message" placeholder="Please type your message here..."
                                        rows="5"></textarea>
                                    <label class="control-label" for="message">Message</label>
                                </div>
                            </div>

                          
                            <div class="form-group">
                                <div class="col-md-12 text-right">
                                    <button type="submit" class="btn btn-primary mt-3">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>


        </div> -->
        <!-- / .row -->

        <!-- <div class="row cntd">
            <div class="col-md-6">
                <h2>FOR <span style="color:#ED3833">GTA</span> REGION</h2>
                <a href="mailto: info@teamdelivery.ca">
                    <span>
                        <i class="fa fa-envelope"></i> </span>
                    <span>info@teamdelivery.ca</span>
                </a>
                <a>
                    <span>
                        <i class="fa fa-user"></i> </span>
                    <span>Jaswinder Sharma</span>
                </a>
                <a href="tel:+16477198901">
                    <span>
                        <i class="fa fa-mobile"></i> </span>
                    <span>+1647-719-8901</span>
                </a>
            </div>
            <div class="col-md-6">
                <h2>FOR <span style="color:#ED3833">NIAGARA</span> REGION</h2>
                <a href="mailto: paramveer@teamdelivery.ca">
                    <span>
                        <i class="fa fa-envelope"></i> </span>
                    <span>paramveer@teamdelivery.ca</span>
                </a>
                <a>
                    <span>
                        <i class="fa fa-user"></i> </span>
                    <span>Paramveer Singh</span>
                </a>
                <a href="tel:+16475718407">
                    <span>
                        <i class="fa fa-mobile"></i> </span>
                    <span>+1647-571-8407</span>
                </a>
            </div>
        </div>
        <div class="row" style="height: 40px;"></div>
    </div> -->
    <!-- / .container -->
<!-- </section> -->
<div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-12">
        <div style="width: 100%; padding: 0px 10px;">
            <h4
                style="color: var(--chicago);  font-family: var(--font-family-noto_sans);  font-size: 27px;   font-style: normal;   font-weight: 500;">
                Pharmacies Listed Under Wize.Health</h4>
            <div
                style=" margin-top: 30px; margin-bottom: 10px; position: relative;   background-color: var(--white);border: 1px solid var(--blue-chill);border-radius: 3px;height: 33px;">
                <input [(ngModel)]="search" (input)="filterPharmacies()" placeholder="Enter Your Zip Code or City Name"
                    id=placeholder type="text"
                    style="width: 100%; outline: none;border: none;height: 31px; padding: 0px 10px;" />
                <div style="display: flex; position: absolute; top: 0;  right: -1px; align-items: center;">
                    <img src="../../assets/new/loc.svg" />
                    <span
                        style="padding: 0px 10px;color: #545665;  font-family: var(--font-family-roboto); font-size: 9.8px; font-style: normal;  font-weight: 400;">Locate
                        Me</span>
                    <img src="../../assets/new/locBtn.svg" />
                </div>
            </div>
            <div
                style=" margin-top: 0px; margin-bottom: 10px; position: relative;   background-color: var(--white);border: 1px solid var(--blue-chill);border-radius: 3px;height: 33px;">
                <input placeholder="Radius" type="text"
                    style="width: 100%; outline: none;border: none;height: 31px; padding: 0px 10px;" />

            </div>
            <div style="width: 100%; ;height: 375px; overflow-y: scroll;">
                <div class="pharmacy" *ngFor="let item of copyPharmacies">
                    <label
                        style=" margin: 0; color: var(--gravel); font-family: var(--font-family-roboto);  font-size: 18px;   font-style: normal; font-weight: 500;">{{item.name}}</label><br />
                    <label style="color: var(--gravel); margin: 0;
    font-family: var(--font-family-roboto);   font-size: 15px;   font-style: normal;  font-weight: 400;">
                        <img src="../../assets/new/mp.svg" style="margin-right:10px;" />{{item.state}},
                        {{item.pin}}</label>
                    <div style="position:  absolute; right: 0;bottom: 0;text-align: center;">
                        <img style="width: 22px;" src="../../assets/new/dir.svg" /><br>
                        <span
                            style="color: var(--blue-chill); font-family: var(--font-family-roboto); font-size: 10.2px; font-style: normal; font-weight: 400;">DIRECTIONS</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <br> <br> <br> <br> <br> <br> <br>
    <div class="col-md-12">

        <google-map height="575px" width="100%" [center]="center" [zoom]="zoom" [options]="options">
            <map-marker *ngFor="let marker of markers" [position]="marker.position" [title]="marker.title">
            </map-marker>
        </google-map>

    </div>
</div>


<div class="modal fade" id="asymp-confirmation">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Covid Asymptomatic Test</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p [innerHTML]="ASYMPTOMATIC_TEST_PRICE"></p>
            </div>
            <div class="modal-footer">
                <button type="button" [routerLink]="['/appointment/covid/asymptomatic-test']" class="btn btn-primary"
                    data-dismiss="modal">
                    Book appointment
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modal-thank-you">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Success</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    Thank you <b>{{ thankYouUser }}</b>, our team will contanct you soon.
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Ok
                </button>
            </div>
        </div>
    </div>
</div>